<template>
  <div class="">
    <div class="c-body" id="body">
      <main class="c-main">
        <CContainer fluid>
          <CSpinner color="primary" style="width: 4rem; height: 4rem" v-if="loading" />

          <AlertBox />

          <CRow>
            <CCol col="12" class="text-center mb-4">
              <img v-if="this.storageURL + location.image" class="center location-logo"
                :src="storageURL + location.image" aria-busy="" />
            </CCol>
          </CRow>

          <transition name="fade">
            <router-view></router-view>
          </transition>
        </CContainer>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LocationAPI from "/app/src/api/location.js";

export default {
  name: "CustomerBookingContainer",
  components: {},
  data() {
    return {
      locationAPI: new LocationAPI(),
      storageURL: process.env.VUE_APP_STORAGE_URL,
      location: {},
    };
  },
  computed: mapState(["alert", "loading"]),
  created: function () {
    this.getLocationDetails(this.$route.params.id);
  },
  methods: {
    getLocationDetails(location_id) {
      console.log("get location");
      this.$store.dispatch("loading");
      let self = this;
      this.locationAPI
        .detail(location_id)
        .then((location) => {
          self.location = location;
          this.$store.dispatch("stopLoading");
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
  },
};
</script>

<style scoped>
.spinner-border {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  z-index: 10;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

::v-deep .vdatetime-calendar__month {
  min-height: 312px;
}

::v-deep img {
  border-radius: 4px !important;
}

.location-logo {
  height: 150px;
  max-width: 100%;
  object-fit: contain;
}
</style>